import { useCallback } from 'react'
import { listPathWithParamsForNextLink } from '@lifedot/router'
import { sectionTypes as sectionTypesCons } from '@lifedot/constants/searchChoices'
import { useListPage } from '@/hooks/use-list-page'
import { UrlObject } from 'url'

export const useSectionTypeLinks = (): {
  getQuery: (type: string) => undefined | UrlObject
  searchingSectionTypes: string[]
} => {
  const {
    prefecture: { roma: prefectureRoma },
    searchParams: {
      cityIds,
      stationIds,
      conditions,
      sort,
      sectionTypes: searchingSectionTypes
    }
  } = useListPage()

  const getQuery = useCallback(
    (sectionTypeCode: string) => {
      const selected = sectionTypesCons.find(
        ({ code }) => code === sectionTypeCode
      )
      if (!selected) return

      const isSearching = searchingSectionTypes?.some(
        (code) => code === sectionTypeCode
      )
      const [pathname, query] = listPathWithParamsForNextLink({
        prefectureRoma,
        stationId: stationIds,
        cityId: cityIds,
        condition: isSearching
          ? conditions.filter(({ code }) => code !== sectionTypeCode)
          : [
              ...conditions,
              { code: selected.code, attributeCode: selected.attributeCode }
            ],
        order: sort.field
      })
      return { pathname, query }
    },
    [
      cityIds,
      conditions,
      prefectureRoma,
      searchingSectionTypes,
      sort.field,
      stationIds
    ]
  )

  return { getQuery, searchingSectionTypes: searchingSectionTypes ?? [] }
}
