/**
 * @generated SignedSource<<2ab4ecc0fd578f748aad26ceae0d1bfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime'
export type getCities_Query$variables = {
  ids: ReadonlyArray<number>
}
export type getCities_Query$data = {
  readonly cities: ReadonlyArray<{
    readonly name: string
  } | null>
}
export type getCities_Query = {
  variables: getCities_Query$variables
  response: getCities_Query$data
}

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids'
      }
    ],
    v1 = [
      {
        alias: 'cities',
        args: [
          {
            kind: 'Variable',
            name: 'ids',
            variableName: 'ids'
          }
        ],
        concreteType: 'City',
        kind: 'LinkedField',
        name: 'batchGetCities',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ]
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'getCities_Query',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'getCities_Query',
      selections: v1 /*: any*/
    },
    params: {
      cacheID: 'db6b216bd7e569c6ce96610b89990812',
      id: null,
      metadata: {},
      name: 'getCities_Query',
      operationKind: 'query',
      text: 'query getCities_Query(\n  $ids: [Int!]!\n) {\n  cities: batchGetCities(ids: $ids) {\n    name\n  }\n}\n'
    }
  }
})()

;(node as any).hash = '2fcdd55320bbde651312d639f5d71e99'

export default node
