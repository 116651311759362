import { FC } from 'react'
import { css } from '@emotion/react'
import Image from 'next/image'
import Link from 'next/link'
import { ResponsiveContents } from '../ResponsiveContents'
import { AddMarginWrapper } from '../AddMarginWrapper'
import { makeBanners, BannersProps } from './dependencies'

const styles = {
  image: css({
    fontSize: 0
  })
}

export interface ResponsiveBannerProps {
  banners: BannersProps
  priority?: boolean
  pcSize: { width: number; height: number }
  spSize: { width: number; height: number }
}

export const ResponsiveBanner: FC<ResponsiveBannerProps> = ({
  banners,
  pcSize,
  spSize,
  priority = false
}) => {
  const bannerItems = makeBanners(banners)
  return (
    <AddMarginWrapper spacing={4}>
      {bannerItems.map((banner) => (
        <Link href={banner.link} prefetch={false} passHref key={banner.link}>
          <a css={styles.image} target="_blank" rel="noopener noreferrer">
            <ResponsiveContents
              desktopContents={
                <Image
                  src={banner.desktopSrc}
                  alt={banner.alt}
                  width={pcSize.width}
                  height={pcSize.height}
                  priority={priority}
                />
              }
              mobileContents={
                <Image
                  src={banner.mobileSrc}
                  alt={banner.alt}
                  width={spSize.width}
                  height={spSize.height}
                  priority={priority}
                />
              }
            />
          </a>
        </Link>
      ))}
    </AddMarginWrapper>
  )
}
