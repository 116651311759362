/**
 * @generated SignedSource<<fb296bd4d9d715f07f31c9173717cdb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime'
export type getStations_Query$variables = {
  ids: ReadonlyArray<number>
}
export type getStations_Query$data = {
  readonly stations: ReadonlyArray<{
    readonly name: string
  } | null>
}
export type getStations_Query = {
  variables: getStations_Query$variables
  response: getStations_Query$data
}

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids'
      }
    ],
    v1 = [
      {
        alias: 'stations',
        args: [
          {
            kind: 'Variable',
            name: 'ids',
            variableName: 'ids'
          }
        ],
        concreteType: 'Station',
        kind: 'LinkedField',
        name: 'batchGetStations',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ]
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'getStations_Query',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'getStations_Query',
      selections: v1 /*: any*/
    },
    params: {
      cacheID: 'e0cfa34ae17e7f698be09af4cd8d9052',
      id: null,
      metadata: {},
      name: 'getStations_Query',
      operationKind: 'query',
      text: 'query getStations_Query(\n  $ids: [Int!]!\n) {\n  stations: batchGetStations(ids: $ids) {\n    name\n  }\n}\n'
    }
  }
})()

;(node as any).hash = '9498a4b154a228d1033f745d2c4a06ff'

export default node
