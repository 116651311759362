import { cloneElement, FC, ReactElement, ReactNode, useState } from 'react'
import { Props as ModalProps, Styles as ModalStyles } from 'react-modal'
import dynamic from 'next/dynamic'
import { palette } from '@lifedot/styles/palette'
import { rgba } from 'emotion-rgba'
import { zIndex } from '@lifedot/styles/zIndex'

interface ModalHandleHelpers {
  handleOpen: () => void
  handleClose: () => void
}

interface ModalWithButton {
  button: ReactElement
  children: (arg: ModalHandleHelpers) => ReactNode
}

export const ModalWithButton: FC<ModalWithButton> = ({ button, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const newButton = cloneElement(button, { onClick: handleOpen })

  if (!isOpen) return newButton

  const Modal = dynamic<ModalProps>(() => import('react-modal'))

  return (
    <>
      {newButton}
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={customStyles}
        ariaHideApp={false}
      >
        {children({ handleOpen, handleClose })}
      </Modal>
    </>
  )
}

const customStyles: ModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: rgba(palette.black, 0.6),
    zIndex: zIndex.modal
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    maxWidth: 710,
    borderRadius: 4,
    padding: 0,
    overflow: 'initial',
    border: 'none'
  }
}
