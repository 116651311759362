import { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { elevation } from '@lifedot/styles/elevation'

const styles = {
  root: css({
    width: '100%',
    backgroundColor: palette.white,
    boxShadow: elevation[1],
    display: 'flex',
    fontWeight: 'bold',
    padding: 10,
    border: `1px solid ${palette.gray2}`
  }),
  title: css({
    width: 140,
    flexShrink: 0,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    [mq('sp')]: {
      width: 90
    }
  }),
  content: css({
    color: palette.gray5,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 280px)',
    [mq('sp')]: {
      width: 'calc(100% - 180px)'
    }
  }),
  action: css({
    color: palette.main03,
    width: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [mq('sp')]: {
      width: 90
    }
  }),
  arrow: css({
    verticalAlign: 'middle'
  })
}

interface SearchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  titleLabel: ReactNode
  contentLabel: ReactNode
}

export const SearchButton: FC<SearchButtonProps> = ({
  titleLabel,
  contentLabel,
  ...props
}) => {
  return (
    <button css={styles.root} {...props}>
      <span css={[typography.textM, styles.title]}>{titleLabel}</span>
      <span css={[typography.textM, styles.content]}>{contentLabel}</span>
      <span css={[typography.textS, styles.action]}>
        変更する
        <KeyboardArrowDownIcon fontSize="small" css={styles.arrow} />
      </span>
    </button>
  )
}
