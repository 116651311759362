import { FC } from 'react'
import { css } from '@emotion/react'
import { SearchButton } from './SearchButton'
import ListIcon from '@material-ui/icons/List'
import RoomIcon from '@material-ui/icons/Room'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { SearchSectionTypeLinksDummy } from '../SearchSectionTypeLinks/SearchSectionTypeLinksDummy'

const styles = {
  wrapper: css({
    [mq('sp')]: {
      padding: '0 8px'
    }
  }),
  search: css({
    '> :first-of-type': {
      borderRadius: '8px 8px 0 0'
    },
    '> :last-of-type': {
      borderRadius: '0 0 8px 8px'
    }
  }),
  icon: css({
    color: palette.main,
    verticalAlign: 'middle'
  }),
  condition: css({
    color: palette.gray6
  })
}

export const SearchButtonsDummy: FC = () => {
  return (
    <div css={styles.wrapper}>
      <AddMarginWrapper spacing={3}>
        <div css={styles.search}>
          <SearchButton
            titleLabel={
              <>
                <RoomIcon css={styles.icon} />
                エリア
              </>
            }
            contentLabel={'市区町村・駅を選択'}
          />
          <SearchButton
            titleLabel={
              <>
                <ListIcon css={styles.icon} />
                詳細条件
              </>
            }
            contentLabel={'こだわり条件を選択'}
          />
        </div>
        <SearchSectionTypeLinksDummy />
      </AddMarginWrapper>
    </div>
  )
}
