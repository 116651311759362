import { FC } from 'react'
import { css } from '@emotion/react'
import { PageTitle } from './PageTitle'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  title: css({
    paddingTop: 16,
    [mq('sp')]: {
      padding: '16px 8px 0'
    }
  })
}

export const PageTitleStyled: FC = () => (
  <div css={styles.title}>
    <PageTitle />
  </div>
)
