import { useMemo } from 'react'
import { useQuery } from 'relay-hooks/lib/useQuery'
import { getCities } from '@/queries/clientSide/getCities'
import { getStations } from '@/queries/clientSide/getStations'
import { getCities_Query } from '@/queries/clientSide/__generated__/getCities_Query.graphql'
import { getStations_Query } from '@/queries/clientSide/__generated__/getStations_Query.graphql'
import { useListPage } from '@/hooks/use-list-page'

export const useSearchButtonAreaLabel = (): string => {
  const {
    city,
    station,
    searchParams: { cityIds, stationIds }
  } = useListPage()

  const aryCityIds = Array.isArray(cityIds) ? cityIds.slice(0, 10) : []
  const aryStationIds = Array.isArray(stationIds) ? stationIds.slice(0, 10) : []

  const { data: cities } = useQuery<getCities_Query>(
    getCities,
    { ids: aryCityIds },
    {
      skip: !!city || aryCityIds.length < 1
    }
  )

  const { data: stations } = useQuery<getStations_Query>(
    getStations,
    { ids: aryStationIds },
    {
      skip: !!station || aryStationIds.length < 1
    }
  )

  return useMemo(() => {
    return (
      city?.name ??
      station?.name ??
      cities?.cities
        ?.map((item) => item?.name)
        ?.filter(Boolean)
        ?.join('／') ??
      stations?.stations
        ?.map((item) => (item?.name ? `${item?.name}駅` : null))
        ?.filter(Boolean)
        ?.join('／') ??
      ''
    )
  }, [cities?.cities, city?.name, station?.name, stations?.stations])
}
