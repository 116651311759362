import { useMemo } from 'react'
import { allSearchChoices } from '@lifedot/constants/searchChoices'
import { useListPage } from '@/hooks/use-list-page'

export const useSearchConditionLabel = (): string => {
  const {
    searchParams: { conditions }
  } = useListPage()

  return useMemo(
    () =>
      conditions
        .map(
          (condition) =>
            allSearchChoices.find(({ code }) => code === condition.code)?.name
        )
        .join('／'),
    [conditions]
  )
}
