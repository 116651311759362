import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { sectionTypes as sectionTypesCons } from '@lifedot/constants/searchChoices'

const styles = {
  list: css({
    li: {
      width: '100%',
      ':not(:first-of-type)': {
        marginLeft: 4
      }
    }
  }),
  title: css({
    fontWeight: 'bold',
    marginBottom: 4
  }),
  button: css({
    display: 'block',
    textAlign: 'center',
    width: '100%',
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    borderRadius: 4,
    color: palette.gray6,
    lineHeight: '40px',
    fontWeight: 'bold',
    padding: 0,
    [mq('sp')]: {
      lineHeight: '36px'
    }
  })
}

export const SearchSectionTypeLinksDummy: FC = () => {
  return (
    <>
      <h2 css={[typography.textM, styles.title]}>お墓タイプから選ぶ</h2>
      <div css={styles.list}>
        <List orientation="horizontal">
          {sectionTypesCons.map(({ code, name }) => (
            <ListItem key={code}>
              <a key={code} css={[typography.textS, styles.button]}>
                {name}
              </a>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  )
}
