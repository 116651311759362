import { FC } from 'react'
import { css } from '@emotion/react'
import ContentLoader from 'react-content-loader'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'

const styles = {
  titleLoader: css({
    height: 52,
    width: '100%',
    [mq('sp')]: {
      height: 42
    }
  }),
  title: css({
    x: 0,
    y: 14,
    rx: 5,
    ry: 5,
    width: 400,
    height: 38,
    [mq('sp')]: {
      x: 8,
      y: 14,
      width: 260,
      height: 28
    }
  })
}

export const PageTitleDummy: FC = () => {
  return (
    <ContentLoader css={styles.titleLoader} backgroundColor={palette.gray2}>
      <rect css={styles.title} />
    </ContentLoader>
  )
}
