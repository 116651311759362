import { FC } from 'react'
import { css } from '@emotion/react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { ModalWithButton } from '@lifedot/components/ModalWithButton'
import { SearchButton } from './SearchButton'
import ListIcon from '@material-ui/icons/List'
import RoomIcon from '@material-ui/icons/Room'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { useSearchButtonAreaLabel } from './dependencies'
import { useSearchConditionLabel } from '@/hooks/use-search-condition-label'
import { EventTracker } from '@lifedot/tracking'
import { AreaSelectPanelDynamic, ConditionSelectPanelDynamic } from './Dynamics'
import { SearchSectionTypeLinks } from '@/components/SearchSectionTypeLinks/SearchSectionTypeLinks'
import { SearchSectionTypeLinks_sectionTypes$key } from '@/components/SearchSectionTypeLinks/__generated__/SearchSectionTypeLinks_sectionTypes.graphql'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  wrapper: css({
    [mq('sp')]: {
      padding: '0 8px'
    }
  }),
  search: css({
    '> :first-of-type': {
      borderRadius: '8px 8px 0 0'
    },
    '> :last-of-type': {
      borderRadius: '0 0 8px 8px'
    }
  }),
  icon: css({
    color: palette.main,
    verticalAlign: 'middle'
  }),
  condition: css({
    color: palette.gray6
  }),
  empty: css({
    height: 71,
    [mq('sp')]: {
      height: 64
    }
  })
}

interface SearchButtonsWithPanelProps {
  sectionTypes: SearchSectionTypeLinks_sectionTypes$key
}

export const SearchButtonsWithPanel: FC<SearchButtonsWithPanelProps> = ({
  sectionTypes
}) => {
  const contentLabelArea = useSearchButtonAreaLabel()
  const contentLabelCondition = useSearchConditionLabel()

  return (
    <div css={styles.wrapper}>
      <AddMarginWrapper spacing={3}>
        <div css={styles.search}>
          <ModalWithButton
            button={
              <EventTracker
                clone
                label="area_開ボタン"
                action="click"
                elementCategory={elementCategories.selectArea}
                elementLabel="open"
              >
                <SearchButton
                  titleLabel={
                    <>
                      <RoomIcon css={styles.icon} />
                      エリア
                    </>
                  }
                  contentLabel={
                    contentLabelArea ? (
                      <span css={styles.condition}>{contentLabelArea}</span>
                    ) : (
                      '市区町村・駅を選択'
                    )
                  }
                />
              </EventTracker>
            }
          >
            {({ handleClose }) => (
              <AreaSelectPanelDynamic onClose={handleClose} />
            )}
          </ModalWithButton>
          <ModalWithButton
            button={
              <EventTracker
                clone
                label="detail_開ボタン"
                action="click"
                elementCategory={elementCategories.selectConditions}
                elementLabel="open"
              >
                <SearchButton
                  titleLabel={
                    <>
                      <ListIcon css={styles.icon} />
                      詳細条件
                    </>
                  }
                  contentLabel={
                    contentLabelCondition ? (
                      <span css={styles.condition}>
                        {contentLabelCondition}
                      </span>
                    ) : (
                      'こだわり条件を選択'
                    )
                  }
                />
              </EventTracker>
            }
          >
            {({ handleClose }) => (
              <ConditionSelectPanelDynamic onClose={handleClose} />
            )}
          </ModalWithButton>
        </div>
        <SearchSectionTypeLinks sectionTypes={sectionTypes} />
      </AddMarginWrapper>
    </div>
  )
}
