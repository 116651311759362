import { FC, ReactNode, useState } from 'react'
import { EventTracker } from '@lifedot/tracking'
import { Tab, TabPanel, Tabs } from '@lifedot/atoms/Tabs'
import { css } from '@emotion/react'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  root: css({
    display: 'grid',
    gap: 24,
    gridTemplateColumns: '100%',
    '[aria-selected="false"] h2': {
      fontWeight: 'normal'
    }
  })
}

interface ListOrMapSelectTabsProps {
  listContent: ReactNode
  mapContent: ReactNode
}

export const ListOrMapSelectTabs: FC<ListOrMapSelectTabsProps> = ({
  listContent,
  mapContent
}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const handleTab = (value: number) => {
    setCurrentTab(value)
  }
  return (
    <div css={styles.root}>
      <Tabs handleTab={handleTab} value={currentTab}>
        <Tab
          label={
            <EventTracker
              label="tab_一覧から探す"
              action="click"
              elementCategory={elementCategories.selectTab}
              elementLabel="一覧から探す"
            >
              <h2>一覧から探す</h2>
            </EventTracker>
          }
        />
        <Tab
          label={
            <EventTracker
              label="tab_地図から探す"
              action="click"
              elementCategory={elementCategories.selectTab}
              elementLabel="地図から探す"
            >
              <h2>地図から探す</h2>
            </EventTracker>
          }
        />
      </Tabs>
      <TabPanel vanishing index={0} value={currentTab}>
        {listContent}
      </TabPanel>
      <TabPanel vanishing index={1} value={currentTab}>
        {mapContent}
      </TabPanel>
    </div>
  )
}
