/**
 * @generated SignedSource<<f63e60b42e37c55f8b7e79413d63d555>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type SearchSectionTypeLinks_sectionTypes$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryCount: number
    readonly code: string
    readonly name: string
  }>
  readonly ' $fragmentType': 'SearchSectionTypeLinks_sectionTypes'
}
export type SearchSectionTypeLinks_sectionTypes$key = {
  readonly ' $data'?: SearchSectionTypeLinks_sectionTypes$data
  readonly ' $fragmentSpreads': FragmentRefs<'SearchSectionTypeLinks_sectionTypes'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'SearchSectionTypeLinks_sectionTypes',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'SearchChoiceWithCemeteryCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cemeteryCount',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'SearchChoiceWithCemeteryCountConnection',
  abstractKey: null
}

;(node as any).hash = 'c296f261be2e1773f671d1275603f9e8'

export default node
