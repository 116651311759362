export type BannersProps = ReadonlyArray<{
  link: string | null
  alt: string | null
  desktopSrc: string | null
  mobileSrc: string | null
}>

export const makeBanners = (banners: BannersProps) => {
  return banners
    .map(({ link, alt, desktopSrc, mobileSrc }) => {
      if (!link || !alt || !desktopSrc || !mobileSrc) return null
      return { link, alt, desktopSrc, mobileSrc }
    })
    .filter((banner): banner is Exclude<typeof banner, null> => !!banner)
}
