import { FC, HTMLAttributes } from 'react'
import { css } from '@emotion/react'

const styles = {
  hidden: css({
    display: 'none'
  })
}

export interface TabPanelProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  index: number
  vanishing?: boolean
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { value, index, children, vanishing = false, ...rest } = props
  const selected = value === index
  return (
    <div
      css={!selected && styles.hidden}
      role="tabpanel"
      aria-hidden={!selected}
      {...rest}
    >
      {!vanishing || selected ? children : null}
    </div>
  )
}
