import { FC } from 'react'
import { AreaSelectPanelProps } from '../SearchPanel/AreaSelectPanel'
import dynamic from 'next/dynamic'
import { ConditionSelectPanelProps } from '../SearchPanel/ConditionSelectPanel'
import { Loading } from './Loading'

export const AreaSelectPanelDynamic: FC<AreaSelectPanelProps> = ({
  onClose
}) => {
  const AreaSelectPanel = dynamic<AreaSelectPanelProps>(
    () =>
      import('../SearchPanel/AreaSelectPanel').then(
        (mod) => mod.AreaSelectPanel
      ),
    {
      loading: Loading
    }
  )

  return <AreaSelectPanel onClose={onClose} />
}

export const ConditionSelectPanelDynamic: FC<ConditionSelectPanelProps> = ({
  onClose
}) => {
  const ConditionSelectPanel = dynamic<ConditionSelectPanelProps>(
    () =>
      import('../SearchPanel/ConditionSelectPanel').then(
        (mod) => mod.ConditionSelectPanel
      ),
    {
      loading: Loading
    }
  )

  return <ConditionSelectPanel onClose={onClose} />
}
