import { FC } from 'react'
import { typography } from '@lifedot/styles/typography'
import { usePageTitle } from './dependencies'
import { css } from '@emotion/react'

const styles = {
  title: css({
    lineHeight: 1.4
  })
}

export const PageTitle: FC = () => {
  const [firstTxt, secondTxt] = usePageTitle()

  return (
    <h1 css={[typography.textXL, styles.title]}>
      {firstTxt}
      <span css={typography.textM}>{secondTxt}</span>
    </h1>
  )
}
