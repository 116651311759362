import type { FC } from 'react'
import { dayjs } from '@lifedot/utils'
import isBetween from 'dayjs/plugin/isBetween'
import { useIsPeriod } from '@lifedot/hooks/use-is-period'
import {
  CAMPAIGN_START_ON,
  CAMPAIGN_END_ON
} from '@lifedot/constants/campaignPeriod'
import { css } from '@emotion/react'
import ContentLoader from 'react-content-loader'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'

const styles = {
  bannerLoader: css({
    height: 140,
    width: '100%',
    [mq('sp')]: {
      height: 72
    }
  }),
  banner: css({
    x: 0,
    y: 0,
    rx: 5,
    ry: 5,
    width: '100%',
    height: 140,
    [mq('sp')]: {
      height: 72
    }
  })
}

dayjs.extend(isBetween)

export const CampaignBannerDummy: FC = () => {
  const isCampaignPeriod = useIsPeriod(CAMPAIGN_START_ON, CAMPAIGN_END_ON)
  if (!isCampaignPeriod) return null
  return (
    <ContentLoader css={styles.bannerLoader} backgroundColor={palette.gray2}>
      <rect css={styles.banner} />
    </ContentLoader>
  )
}
