import type { FC } from 'react'
import { ResponsiveBanner } from '../ResponsiveBanner'
import { ResponsiveContents } from '../ResponsiveContents'
import Image from 'next/image'
import { dayjs } from '@lifedot/utils'
import isBetween from 'dayjs/plugin/isBetween'
import { router } from '@lifedot/router'
import { useIsPeriod } from '@lifedot/hooks/use-is-period'
import {
  CAMPAIGN_START_ON,
  CAMPAIGN_END_ON
} from '@lifedot/constants/campaignPeriod'

interface CampaignBannerProps {
  showDetail?: boolean
}

dayjs.extend(isBetween)

export const CampaignBanner: FC<CampaignBannerProps> = ({ showDetail }) => {
  const isCampaignPeriod = useIsPeriod(CAMPAIGN_START_ON, CAMPAIGN_END_ON)
  if (!isCampaignPeriod) return null
  return (
    <>
      {showDetail ? (
        <ResponsiveContents
          desktopContents={
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/87256/bn_campaign_2024_ef_pc.png"
              alt="お墓見学とご成約で最大10,000円分のAmazonギフト券プレゼント中"
              width={1720}
              height={368}
            />
          }
          mobileContents={
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/87254/bn_campaign_2024_ef_sp.png"
              alt="お墓見学とご成約で最大10,000円分のAmazonギフト券プレゼント中"
              width={860}
              height={206}
            />
          }
        />
      ) : (
        <ResponsiveBanner
          banners={[
            {
              link: router.post('campaign_202403'),
              alt: 'お墓見学とご成約で最大10,000円分のAmazonギフト券プレゼント中',
              desktopSrc:
                'https://s3.lifedot.jp/uploads/ending/path/87255/bn_campaign_2024_pc.png',
              mobileSrc:
                'https://s3.lifedot.jp/uploads/ending/path/87253/bn_campaign_2024_sp.png'
            }
          ]}
          pcSize={{ width: 1720, height: 280 }}
          spSize={{ width: 750, height: 144 }}
        />
      )}
    </>
  )
}
