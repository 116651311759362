import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { EventTracker } from '@lifedot/tracking'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { SearchSectionTypeLinks_sectionTypes$key } from './__generated__/SearchSectionTypeLinks_sectionTypes.graphql'
import { orderedSectionType } from '@lifedot/utils'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { SectionTypeLink } from './SectionTypeLink'

const styles = {
  list: css({
    display: 'flex',
    gap: 4,
    li: {
      width: '100%'
    }
  }),
  title: css({
    fontWeight: 'bold',
    marginBottom: 4
  })
}

interface SearchSectionTypeLinksProps {
  sectionTypes: SearchSectionTypeLinks_sectionTypes$key
}

export const SearchSectionTypeLinks: FC<SearchSectionTypeLinksProps> = ({
  sectionTypes
}) => {
  const data = useFragment(fragment, sectionTypes)
  const sorted = useMemo(
    () => orderedSectionType([...data.items]),
    [data.items]
  )

  return (
    <>
      <h2 css={[typography.textM, styles.title]}>お墓タイプから選ぶ</h2>
      <ul css={styles.list}>
        {sorted.map(({ code, name, cemeteryCount }) => (
          <li key={code}>
            <EventTracker
              clone
              label={`type_${name}`}
              action="click"
              elementCategory={elementCategories.selectSectiontype}
              elementLabel={name}
            >
              <SectionTypeLink
                cemeteryCount={cemeteryCount}
                name={name}
                code={code}
              />
            </EventTracker>
          </li>
        ))}
      </ul>
    </>
  )
}

const fragment = graphql`
  fragment SearchSectionTypeLinks_sectionTypes on SearchChoiceWithCemeteryCountConnection {
    items {
      cemeteryCount
      code
      name
    }
  }
`
