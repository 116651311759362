import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  empty: css({
    height: 71,
    [mq('sp')]: {
      height: 64
    }
  })
}

export const Loading: FC = () => {
  return <div css={styles.empty} />
}
