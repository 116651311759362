import { FC } from 'react'
import { css } from '@emotion/react'
import ContentLoader from 'react-content-loader'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'

const styles = {
  wrap: css({
    [mq('sp')]: {
      padding: 12
    }
  }),
  img: css({
    x: 0,
    y: 15,
    rx: 5,
    ry: 5,
    width: 350,
    height: 300,
    [mq('sp')]: {
      width: '100%',
      height: 200
    }
  }),
  title: css({
    x: 380,
    y: 15,
    rx: 5,
    ry: 5,
    width: 350,
    height: 40,
    [mq('sp')]: {
      x: 0,
      y: 230,
      width: '100%',
      height: 30
    }
  }),
  text: css({
    x: 380,
    y: 70,
    rx: 5,
    ry: 5,
    width: 320,
    height: 20,
    [mq('sp')]: {
      x: 0,
      y: 270,
      width: '100%',
      height: 20
    }
  })
}

export const CemeteryListDummy: FC = () => {
  return (
    <div css={styles.wrap}>
      <ContentLoader
        width={'100%'}
        height={320}
        title="Loading"
        backgroundColor={palette.gray2}
      >
        <rect css={styles.img} />
        <rect css={styles.title} />
        <rect css={styles.text} />
      </ContentLoader>
      <ContentLoader
        width={'100%'}
        height={320}
        title="Loading"
        backgroundColor={palette.gray2}
      >
        <rect css={styles.img} />
        <rect css={styles.title} />
        <rect css={styles.text} />
      </ContentLoader>
      <ContentLoader
        width={'100%'}
        height={320}
        title="Loading"
        backgroundColor={palette.gray2}
      >
        <rect css={styles.img} />
        <rect css={styles.title} />
        <rect css={styles.text} />
      </ContentLoader>
      <ContentLoader
        width={'100%'}
        height={320}
        title="Loading"
        backgroundColor={palette.gray2}
      >
        <rect css={styles.img} />
        <rect css={styles.title} />
        <rect css={styles.text} />
      </ContentLoader>
      <ContentLoader
        width={'100%'}
        height={320}
        title="Loading"
        backgroundColor={palette.gray2}
      >
        <rect css={styles.img} />
        <rect css={styles.title} />
        <rect css={styles.text} />
      </ContentLoader>
    </div>
  )
}
