import { FC } from 'react'
import { css } from '@emotion/react'
import { SingleColumn } from '@lifedot/layout'
import { BreadcrumbDummy } from '@/components/Breadcrumb/BreadcrumbDummy'
import { SearchButtonsDummy } from '@/components/SearchButtonsWithPanel'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { PageTitleDummy } from '@/components/PageTitle'
import { CemeteryListDummy } from '@/components/CemeteryList/CemeteryListDummy'
import { ListOrMapSelectTabs } from '@/components/ListOrMapSelectTabs'
import { PageDescriptionDummy } from '@/components/PageDescription/PageDescriptionDummy'
import { CampaignBannerDummy } from '@lifedot/components/CampaignBanner'

const styles = {
  wrap: css({
    margin: '4px auto 0'
  })
}

export const CommonContentsDummy: FC = () => {
  return (
    <div css={styles.wrap}>
      <SingleColumn>
        <BreadcrumbDummy />
        <PageTitleDummy />
        <PageDescriptionDummy />
        <AddMarginWrapper spacing={3}>
          <SearchButtonsDummy />
          <ListOrMapSelectTabs
            listContent={
              <>
                <CampaignBannerDummy />
                <CemeteryListDummy />
              </>
            }
            mapContent=""
          />
        </AddMarginWrapper>
      </SingleColumn>
    </div>
  )
}
