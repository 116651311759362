import { useListPage } from '@/hooks/use-list-page'
import { needConditionSuffix } from '@/utils/need-condition-suffix'

export const usePageTitle = (): [string, string] => {
  const { prefecture, city, station, condition, pagination } = useListPage()
  const paging =
    pagination.currentPageNumber > 1
      ? `(${pagination.currentPageNumber}ページ目)`
      : ''
  const conditionText = condition
    ? needConditionSuffix(condition.code)
      ? `${condition.name}の`
      : condition.name
    : ''
  const titleSuffix = 'おすすめランキング・霊園一覧'
  if (city)
    return [
      `${paging}${city.name}（${prefecture.name}）にある${conditionText}お墓`,
      `${titleSuffix}`
    ]
  if (station)
    return [
      `${paging}${station.name}駅周辺にある${conditionText}お墓`,
      `${titleSuffix}`
    ]
  return [
    `${paging}${prefecture.name}にある${conditionText}お墓`,
    `${titleSuffix}`
  ]
}
