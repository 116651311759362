import { FC, ReactNode, useCallback, ButtonHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import noop from 'lodash.noop'

const styles = {
  root: css({
    width: '100%'
  }),
  button: (index: TabProps['index']) =>
    css({
      width: '100%',
      color: palette.black,
      lineHeight: '44px', //最低限のボタンの高さ
      textAlign: 'center',
      borderRight: `1px solid ${palette.gray4}`,
      borderTop: `1px solid ${palette.gray4}`,
      borderBottom: `1px solid ${palette.gray4}`,
      borderLeft: `${index === 0 ? '1px' : 0} solid ${palette.gray4}`,
      backgroundColor: palette.white
    }),
  selected: css({
    backgroundColor: 'transparent',
    borderBottom: 'none',
    fontWeight: 'bold'
  })
}

export interface TabProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  label: ReactNode
  index?: number
  onClick?: (value: number) => void
  selected?: boolean
}

export const Tab: FC<TabProps> = (props) => {
  const { onClick = noop, selected = false, label, index = 0, ...rest } = props
  const handleClick = useCallback(() => onClick(index), [onClick, index])
  return (
    <button
      css={[styles.button(index), selected && styles.selected]}
      onClick={handleClick}
      role="tab"
      aria-selected={selected}
      tabIndex={selected ? 0 : -1}
      {...rest}
    >
      {label}
    </button>
  )
}
