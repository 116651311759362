import Link from 'next/link'
import type { FC } from 'react'
import { useSectionTypeLinks } from './dependencies'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

type SectionTypeLinkProps = {
  cemeteryCount: number
  name: string
  code: string
}

const styles = {
  link: css({
    display: 'block',
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    borderRadius: 4,
    color: palette.gray6,
    lineHeight: '40px',
    textAlign: 'center',
    cursor: 'pointer',
    [mq('sp')]: {
      lineHeight: '36px'
    }
  }),
  linkDisabled: {
    backgroundColor: palette.gray1,
    color: palette.gray5,
    cursor: 'default'
  },
  selected: css({
    backgroundColor: palette.main
  })
}

export const SectionTypeLink: FC<SectionTypeLinkProps> = ({
  cemeteryCount,
  name,
  code
}) => {
  const { getQuery, searchingSectionTypes } = useSectionTypeLinks()
  const href = getQuery(code)
  const isSelected = searchingSectionTypes?.some((type) => type === code)
  const isActiveCemeteryLink = cemeteryCount > 0 && href

  return (
    <h3>
      {isActiveCemeteryLink ? (
        <Link href={href}>
          <a
            css={[typography.textS, styles.link, isSelected && styles.selected]}
          >
            {name}
          </a>
        </Link>
      ) : (
        <a css={[typography.textS, styles.link, styles.linkDisabled]}>{name}</a>
      )}
    </h3>
  )
}
